/* -------------------------------- 

File#: _1_card-v9
Title: Card v9
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card-v9 {
  display: block;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  background-color: var(--color-contrast-lower);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: .3s;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

.card-v9__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.card-v9__btn {
  position: relative;
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  @include fontSmooth;

  i { // button label
    position: relative;
    z-index: 2;
    color: var(--color-bg);
    opacity: 0;
    transition: opacity .3s;
  }

  &::after { // button background
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-md);
    background-color: alpha(var(--color-contrast-higher), 0.8);
    opacity: 0;
    transform: translateY(25%);
    transition: opacity .3s, transform .3s var(--ease-out);
  }
}

.card-v9:hover {
  .card-v9__btn {
    i {
      opacity: 1;
    }

    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// --overlay-bg
.card-v9--overlay-bg {
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: alpha(var(--color-bg), 0.5);
    transition: background-color .3s;
  }

  &:hover::before {
    background-color: alpha(var(--color-bg), 0.8);
  }
}