//
// TYPO3
// --------------------------------------------------
.rte-lead {
	font-size: var(--text-xl);
}

.rte-highlighted {
	font-size: var(--text-md);
}

a.rte-highlighted {
	font-size: inherit;
	text-decoration: none;
	background-color: var(--color-primary);
	color: var(--color-black);
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	transition: background-color 0.3s, color 0.3s;

	&:hover {
		background-color: transparent;
		color: var(--color-primary);
	}
}

.rte-small {
	font-size: var(--text-sm);
	color: var(--color-contrast-medium);
}

a:hover {
	text-decoration: none;
}

//
// Default
// --------------------------------------------------
.sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.min-height-75vh {
	min-height: 75vh;
}

.min-height-50vh {
	min-height: 200px;

	@media (min-width: 768px) {
		min-height: 250px;
	}

	@media (min-width: 1024px) {
		min-height: 400px;
	}

	@media (min-width: 1280px) {
		min-height: 550px;
	}
}


.thumbnail {
	img {
		background-color: #fff;
		border: 1px solid var(--color-contrast-low);
	}

	a,
	img,
	div {
		transition: all 0.3s;
	}

	a:hover {
		.filename_url {
			color: var(--color-primary);
		}
		img {
			transform: scale(1.025);
		}
	}
}

.m0 {
	margin: 0;
}

.mb-0 {
	margin-bottom: 0 !important;
}
